.notification-paper {
    border-radius: 16px !important;
    background-color: #f4f5f6 !important;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.14) !important;
    top: 70px !important;
}

.notification-paper div {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.notification-paper div::-webkit-scrollbar {
    display: none;
}

@media (max-width: 600px) {
    .notification-paper {
        border-radius: 0 !important;
        box-shadow: none !important;
        top: 0 !important;
        left: 0 !important;
        max-width: 100vw !important;
        max-height: 100vh !important;
    }
}
