@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./assets/fonts/HelveticaNowDisplay-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./assets/fonts/HelveticaNowDisplay-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./assets/fonts/HelveticaNowDisplay-RegIta.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./assets/fonts/HelveticaNowDisplay-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./assets/fonts/HelveticaNowDisplay-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./assets/fonts/HelveticaNowDisplay-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./assets/fonts/HelveticaNowDisplay-BoldIta.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./assets/fonts/HelveticaNowDisplay-MedIta.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./assets/fonts/HelveticaNowDisplay-ExtLt.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('./assets/fonts/HelveticaNowDisplay-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

body {
    margin: 0;
    font-family: 'HelveticaNowDisplay', "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.highlight-dark {
    background-color: #69b1cd !important;
    color: #000 !important;
}

.highlight {
    background-color: #d2f2ff !important;
    color: #000 !important;
}

.strike {
    text-decoration: line-through;
    color: #a31620 !important;
    background-color: #f6e6e7;
}

.text-found {
    background-color: yellow;
}

.highlight {
    background-color: rgba(255, 200, 62, 0.3);
}

.MuiButton-textPrimary:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
    background-color: #0659F9 !important;
    color: #fff !important;
    border-color: #0659F9;
}

input[type="range"]::-webkit-slider-thumb {
    opacity: 0;
}

input[type="range"]::-moz-range-thumb {
    opacity: 0;
}

input[type="range"]::-ms-thumb {
    opacity: 0;
}

.link {
    text-decoration: none !important;
    color: #777e91 !important;
}

.active {
    text-decoration: none !important;
    color: #0659F9 !important;
}
